/**
 * This utility ensures child <sup> and <sub> tags (like for Registered
 * symbols (®) and chemical formula subscripts) don't cause the rest of the
 * text to get vertically misaligned.
 */
export var alignSupSub = {
  '& sup, sub': {
    verticalAlign: 'baseline',
    position: 'relative',
    top: '-0.4em'
  },
  '& sub': {
    top: '0.2em'
  }
};